@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
iframe#webpack-dev-server-client-overlay {
	display: none !important;
}
body {
	font-family: "DM Sans", sans-serif;
}

option {
	color: black;
}

.react-tagsinput-input {
	width: 100% !important;
}

.custom-scrollbar {
	&::-webkit-scrollbar {
	  width: 7px; 
	}
	
	&::-webkit-scrollbar-track {
	  width: 8px;
	}
	
	&::-webkit-scrollbar-thumb {
	  background: rgba(108, 122, 137, 0.8);
	  border-radius: 24px;
	}
  }

.tags-input{
	background-color: inherit;
	border: 1px solid gray;
	border-radius: 8px;
	padding: 4px;
}

/* css for react-calendar */

.react-calendar{
	border-radius: 10px 10px 0 0 ;
}

	.react-calendar__navigation{
		text-align: center;
		background:#4299E1;
		font-weight: bolder;
		color: white;
		padding:6px 0px;
		border-radius: 10px 10px 0 0 ;
	}
	.react-calendar__navigation__label{
		background-color: #4299E1!important;
		border-radius: 10px 10px 0 0 ;
	}

	.react-calendar__navigation__prev2-button{
		display: none;
	}
	
	.react-calendar__navigation__prev-button{
		display: none;
	}
	
	.react-calendar__navigation__next-button{
		display: none;
	}
	
	.react-calendar__navigation__next-button{
		display: none;
	}
	
	.react-calendar__navigation__next2-button{
		display: none;
	}
	.react-calendar__month-view__weekdays__weekday abbr{
		border-bottom: none !important;
  		cursor: inherit !important;
  		text-decoration: none !important;
	}
.react-calendar__viewContainer{
	background-color: inherit;
	color: black;
}

.react-calendar__month-view__weekdays__weekday{
	text-align: center;
	margin-bottom: 10px;
	text-transform: uppercase;
}

.react-calendar__month-view__days__day{
	border-radius: 15px;
}

.react-calendar__month-view__days{
	padding:2px
}


  .react-calander_hovered-range {
	background-color: rgba(166, 163, 164, 0.517)!important;
  }
  

  .react-calander_selected-range {
	background-color: #0080ff!important;
	color: white!important;
  }

.react-calander_disabled-dates{
	color: gray!important;
}
.scribble {
	width: 100%; /* Adjust width as needed */
	height: 85px; /* Adjust height */
  }

  .awssld {
	height: 100% !important;
  }

  .awssld__content {
	background: transparent !important;
	height: 100% !important;
  }

  .awssld__content > div {
	height: 100% !important;
	width: 100% !important;
  }


  
  
  .scribble-path {
	position: absolute;
    width: 350px;
	top:-5%;
    left: -152%;
	fill: none;
	stroke: #00a071;
	stroke-width: 4;
	stroke-miterlimit: 0;
	stroke-dasharray: 1650; /* Total length of the path */
	stroke-dashoffset: 1650; /* Start offset for the stroke animation */
	stroke-linecap: round;
	animation: animateScribble 2s ease-out 0.5s forwards;
  }
  
  @keyframes animateScribble {
	to {
	  stroke-dashoffset: 0;
	}
  }

  .react-tagsinput {
	border-radius: 5px;
    border-color: #e2e8f0 !important;
	font-size: 15px;
	background: transparent !important;
  }