#remoteVideo {
    position: relative;
    display:flex;
    align-items:center;
    justify-content:center;
    width: 100%;
    height: 100%;
    border-right: 1px solid #d7d7d7;
    position: relative;
  }
  
  #remoteVideo video {
    position: absolute !important;
    object-fit: contain !important;
    width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
    max-width: 100% !important;
  }

  #locaVideoContainer{
    padding-right: 0;
  }
  
  #localVideo {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 25px;
  }

  @media screen and (max-width:431px){
    #localVideo {
      object-fit: cover !important;
      
    }
    #locaVideoContainer{
      padding-right: 9px;
    }
  }